<template>
  <div class="information">
    <div class="userinfo-container">
      <div class="userinfo-title">考生信息</div>
      <div class="userinfo-list">
        <div class="userinfo-row large">
          <div class="userInfo-item">
            <span />
            <span>座位号：</span>
            <span>{{ examineeInfo ? examineeInfo.seat : '-' }}</span>
          </div>

          <div class="userInfo-item">
            <span />
            <span>姓名：</span>
            <span>{{ examineeInfo ? examineeInfo.examineeName : '-' }}</span>
          </div>
        </div>
        <div class="userinfo-row medium">
          <div class="userInfo-item">
            <span />
            <span>准考证号：</span>
            <span>{{ examineeInfo ? examineeInfo.examRegistrationNumber : '-' }}</span>
          </div>

          <div class="userInfo-item">
            <span />
            <span>身份证号码：</span>
            <span>{{ examineeInfo ? examineeInfo.idNumber : '-' }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="notes">
      <div class="notes__inner">
        <div class="notes-title">
          <span />
          <span>考生须知</span>
          <span />
        </div>
        <div class="notes-content">
          <div>
            1.考生应按规定的时间入场，开始考试后15分钟禁止迟到考生进入考场。
          </div>
          <div>
            2.考生入场时须主动出示《准考证》以及有效身份证件(身份证、军人、
            武警人员证件、未成年人的户口本、公安户籍部门开具的《身份证》号码证明、
            护照或者港、澳、台同胞证件),接受考试工作人员的核验，并按要求在“考生
            花名册”上签自己的姓名。
          </div>
          <div>
            3.考生只准携带必要的文具入场,如铅笔、签字笔、毛笔、水粉水彩颜料等，具
            体要求见招考简章。禁止携带任何已完成作品以及各种无线通信工具(如寻呼机、移动电话)等物品。如
            发现考生携带以上禁带物品，考生将作为违纪处理，取消该次考试成绩。考场内不得擅自相互借用文具。
          </div>
          <div>
            4.考生入场后按号入座，将本人《准考证》以及有效身份证件放在课桌上,以便核验。
          </div>
          <div>
            5.考生答题前应认真填写试卷及答题纸上的姓名、准考证号等栏目并粘贴带有考生个人信息的
            条形码。凡不按要求填写或字迹不清、无法辨认的，试卷及答题纸- -律无效。 责任由考I
            生自付。
          </div>
          <div>
            6.开考后,考生不得中途退场。如因身体不适要求中途退场，须征得监考人员及考点主考批准，
            并在退场前将试卷、答题纸如数上交。
          </div>
          <div>
            7.考生遇试卷分发错误或试题字迹不清等情况应及时要求更换;涉及试题内容的疑问，不得向监考人员询问。
          </div>
          <div>
            8.考生在考场内必须严格遵守考场纪律,对于违反考场规定、不服从监考人员管理和舞弊者，取消当次考试成绩。
          </div>
          <div>
            9.考试结束铃声响时,考生要立即停止答题，并将试卷、答题纸按要求整理好，翻放在桌上，
            待监考人员收齐后方可离开考场。任何考生不准携带试卷、答题纸离开考场。离开考场;
            后不准在考场附近逗留和交谈。
          </div>
          <div>
            10.考生应自觉服从监考人员管理，不得以任何理由妨碍监考人员进行正常工作。
          </div>
        </div>
      </div>
    </div>
    <el-button type="primary" class="confirm" @click="confirm">开始考试</el-button>
  </div>
</template>

<script>
import { getExamineeInfo, getExamInfo } from '@/api'

export default {
  name: 'Index',

  data() {
    return {
      examineeInfo: {},
      examInfo: {}
    }
  },

  mounted() {
    getExamineeInfo().then(({ data }) => {
      this.examineeInfo = data
    }).catch(err => {
      console.log(err)
      this.$message.warning(err.message)
    })

    getExamInfo().then(({ data }) => {
      this.examInfo = data
    }).catch(e => {
      this.$message.error(`获取考试信息失败: ${e.message ?? e.toString()}`)
    })
  },

  methods: {
    confirm() {
      if (Date.now() < this.examInfo.examStartTime) {
        return this.$message.warning('考试尚未开始')
      }

      // this.$confirm('确定开始考试?')
      //   .then(() => {
      //     this.$store.dispatch('startExam', this.examineeInfo.id)
      //       .then(() => {
      //         this.$router.push('/tests')
      //       })
      //       .catch((e) => {
      //         this.$message.error(`开始考试失败：${e.message}`)
      //       })
      //   })
      //   .catch(() => {})

      this.$confirm('确定开始考试?')
        .then(() => {
          this.$store.dispatch('startExam')
            .then(() => {
              this.$router.push('/tests')
            })
            .catch((e) => {
              this.$message.error(`开始考试失败：${e.message}`)
            })
        })
        .catch(() => {})
    }
  }
}
</script>

<style scoped>
.information {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.userinfo-container {
  width: 80vw;
  padding: 20px 0;
  margin-top: 50px;
  border-radius: 8px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.userinfo-title {
  font-size: 1.5vw;
  text-align: center;
  color: #3E7AF3;
}

.userinfo-list {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.userinfo-row {
  display: flex;
  width: 100%;
}

.userinfo-row.large {
  font-size: 4vw;
}

.userinfo-row.medium {
  font-size: 1.8vw;
}

.userInfo-item {
  margin-top: 15px;
  display: flex;
  align-items: center;
  flex: 1;
}

.userInfo-item > span:nth-of-type(1) {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #AEAEAE;
  margin-right: 10px;
}

.userInfo-item > span:nth-of-type(2) {
  color: #9D9D9D;
  font-size: 60%;
}

.notes {
  width: 80vw;
  margin-top: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
}

.notes__inner {
  margin: 10px 50px 40px 50px;
}

.notes-title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.notes-title > span:nth-of-type(1), .notes-title > span:nth-of-type(3) {
  display: inline-block;
  width: 180px;
  height: 2px;
  background: #3E7AF3;
}

.notes-title > span:nth-of-type(2) {
  font-size: 1.5vw;
  margin: 0 10px;
  color: #3E7AF3;
}

.notes-content {
  margin-top: 20px;
}

.notes-content > div {
  font-size: 18px;
  text-align: left;
  margin-top: 10px;
}

.confirm {
  font-size: 1.5vw;
  width: 10vw;
  height: 2.7vw;
  border-radius: 1vw;
  margin-top: 2vw;
}
</style>
